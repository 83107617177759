import { appInsightsLogger } from "core/monitoring/AppInsights";
import { UserState } from "core/state/userState";
import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { User, UserManager } from "oidc-client-ts";
import { ClientCodeState } from "shared/core/state/clientCodeState";
import { fluterUserAuth } from "core/javascriptChannels";
import { trackEvents } from "core/monitoring/Events";

export type TokenRenewalContextType = {
  renewToken: () => void;
};

type TokenRenewalContextProviderProps = {
  userManager: UserManager;
};

export const TokenRenewalContext = createContext<TokenRenewalContextType>({} as TokenRenewalContextType);

export const useTokenRenewalContext = () => useContext(TokenRenewalContext);

export const TokenRenewalContextProvider = (props: PropsWithChildren & TokenRenewalContextProviderProps) => {
  const [startRefresh, setStartRefresh] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const overrideUser = async () => {
    const userAuth = await fluterUserAuth();
    const oidcUser = await props.userManager.getUser();
    trackEvents("Oidc_override_attempt");

    if (userAuth) {
      const flutterUser = User.fromStorageString(userAuth);
      if (oidcUser) {
        //check that their values are not the same
        if (oidcUser.access_token !== flutterUser.access_token || oidcUser.refresh_token !== flutterUser.refresh_token) {
          appInsightsLogger.info("Updating auth and refresh tokens from app");

          //fetch the timestamps of each of their creation
          const flutterUserAuthTimeStamp = flutterUser.profile.iat;
          const oidcUserAuthTimeStamp = oidcUser.profile.iat;

          // check that flutter user was updated more recently than oidc user
          if (flutterUserAuthTimeStamp > oidcUserAuthTimeStamp) {
            appInsightsLogger.info(
              `Flutter user authententication timestamp ${flutterUserAuthTimeStamp} is later than oidc user timestamp ${oidcUserAuthTimeStamp}, updating oidc user with flutter user details`
            );

            // user.access_token = flutterUser.access_token;
            // user.refresh_token = flutterUser.refresh_token;
            await props.userManager.storeUser(flutterUser);
            await props.userManager.getUser(); // Triggers user loaded event to update auth state
            trackEvents("Oidc_override:Flutter_more_recent");
          }
        } else {
          appInsightsLogger.info("Skipping update of auth tokens - background tokens have same value");
          trackEvents("Skip_oidc_override_attempt");
        }
      } else {
        await props.userManager.storeUser(flutterUser);
        await props.userManager.getUser(); // Triggers user loaded event to update auth state
        trackEvents("Oidc_override:No_Oidc_user");
      }
    } else {
      trackEvents("No_flutter_user");
    }
  };

  useEffect(() => {
    const renewToken = async () => {
      function tokenNotExpiringSoon(oidcUser: User) {
        return oidcUser.expires_in != null && oidcUser.expires_in > 300;
      }

      function tokenNotExpired(oidcUser: User) {
        return !oidcUser.expired;
      }

      try {
        const user = UserState.get();
        if (user) {
          appInsightsLogger.info("User available.");
          await overrideUser();

          // oidc user is successfully overriden if flutter user exists, and has replaced the existing user because its more valid.
          // oidc user is not overriden if there is no flutter user or if both flutter and oidc have the same value
          // this means at this point oidc user has the latest tokens and in sync with flutter, so we fetch its value
          const oidcUser = await props.userManager.getUser();
          if (ClientCodeState.isDemoClient()) {
            appInsightsLogger.info(
              `Token Details -  Refresh Token: ${oidcUser?.refresh_token} Access token: ${oidcUser?.access_token}  Expires At: ${
                oidcUser?.expires_at
              }  IsExpired: ${oidcUser?.expired} Expires in: ${oidcUser?.expires_in} IsMobile: ${!!window.flutter_inappwebview ? true : false}`
            );
          }

          // we confirm expiry of the current oidc user access tokens to see if there is a need for silent signin
          if (oidcUser !== null && (tokenNotExpired(oidcUser) || tokenNotExpiringSoon(oidcUser))) {
            trackEvents("Skip_silent_signin");
            appInsightsLogger.info("User available. Not expired or expiring soon. Not renewing token");
            return;
          }

          // if the updates user's access tokens has expired or soon to expire, there is a need to run silent signin wiht the refresh token
          appInsightsLogger.info("User available. Attempting token renewal...");
          trackEvents("Attempt_silent_signin");
          if (ClientCodeState.isDemoClient()) {
            appInsightsLogger.info(
              `onRenew Before Token Details -  Refresh Token: ${oidcUser?.refresh_token} Access token: ${oidcUser?.access_token}  Expires At: ${
                oidcUser?.expires_at
              }  IsExpired: ${oidcUser?.expired} Expires in: ${oidcUser?.expires_in} IsMobile: ${!!window.flutter_inappwebview ? true : false}`
            );
          }
          await props.userManager.signinSilent();
          trackEvents("Silent_signin_success");
          appInsightsLogger.info("Silent token renewal on expiry successful");

          // we dont need to authenticate mobile user after silent signin as that is already done in userloaded event after successful silent signin
        }
      } catch (err) {
        appInsightsLogger.error("Error from signinSilent on expiry:", err);
        if (ClientCodeState.isDemoClient()) {
          const oidcUser = await props.userManager.getUser();
          appInsightsLogger.error(
            `Refresh Token being used in above call that failed is: ${oidcUser?.refresh_token} Access_Token: ${oidcUser?.access_token}  Expires At: ${
              oidcUser?.expires_at
            }  IsExpired: ${oidcUser?.expired} Expires in: ${oidcUser?.expires_in} IsMobile: ${!!window.flutter_inappwebview ? true : false}`
          );
        }
      } finally {
        setIsLocked(false);
        setStartRefresh(false);
      }
    };

    if (startRefresh && !isLocked) {
      setIsLocked(true);
      renewToken();
    } else {
      setStartRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocked, startRefresh]);

  return <TokenRenewalContext.Provider value={{ renewToken: () => setStartRefresh(true) }}>{props.children}</TokenRenewalContext.Provider>;
};
