import { appInsightsLogger } from "core/monitoring/AppInsights";
import { useAuth as authContext, hasAuthParams } from "react-oidc-context";
import { useTokenRenewalContext } from "./TokenRenewalContext";
import { AccessTokenState } from "shared/core/state/accessTokenState";
import { ClientCodeState } from "shared/core/state/clientCodeState";
import { authenticateMobileUser } from "core/javascriptChannels";
import { UserState } from "core/state/userState";
import { trackEvents } from "core/monitoring/Events";

export type UseAuthResult = {
  signinRedirect: Function;
  signoutRedirect: Function;
  signinSilent: Function;
  isAuthenticated: boolean;
  shouldAuthenticate: boolean;
  isLoading: boolean;
};

let addedAuthEvents = false;

export const useAuth = (): UseAuthResult => {
  const { signinRedirect, signoutRedirect, signinSilent, isAuthenticated, isLoading, events, removeUser } = authContext();
  const shouldAuthenticate = !hasAuthParams() && !isAuthenticated && !isLoading;
  const { renewToken } = useTokenRenewalContext();
  const isMobileApp = !!window.flutter_inappwebview;

  // need to know what state addAuthEvents is in - does it need adding if already added in session? If the event listener keeps on getting added, we may need to use useRef to keep track if it has been added more than once
  appInsightsLogger.info(`useAuth component called - addedAuthEvents: ${addedAuthEvents}`);
  if (!addedAuthEvents) {
    appInsightsLogger.info(`addAccessTokenExpired listener being added`);

    events.addAccessTokenExpired((_) => {
      const userInStorage = AccessTokenState.getUser();
      appInsightsLogger.info("Access token expired!");
      trackEvents("Access_token_expired", {
        timeStamp: userInStorage?.profile.iat.toString() ?? "",
        expires: userInStorage?.expires_at?.toString() ?? "",
      });
      renewToken();
    });

    events.addAccessTokenExpiring((_) => {
      appInsightsLogger.info("Access token expiring...");
      const userInStorage = AccessTokenState.getUser();
      trackEvents("Access_token_expiring", {
        timeStamp: userInStorage?.profile.iat.toString() ?? "",
        expires: userInStorage?.expires_at?.toString() ?? "",
      });
      if (ClientCodeState.isDemoClient()) {
        appInsightsLogger.info(
          `Expiring Token Details -  Refresh Token: ${userInStorage?.refresh_token} Access token: ${userInStorage?.access_token}  Expires At: ${userInStorage?.expires_at}  IsExpired: ${userInStorage?.expired} Expires in: ${userInStorage?.expires_in} IsMobile: ${isMobileApp}`
        );
      }
    });

    events.addSilentRenewError((err) => {
      appInsightsLogger.error("Error on silent renew. Removing userInStorage to force re-login.", err);
      const userInStorage = AccessTokenState.getUser();
      trackEvents("Silent_renew_error", {
        timeStamp: userInStorage?.profile.iat.toString() ?? "",
        expires: userInStorage?.expires_at?.toString() ?? "",
      });
      if (ClientCodeState.isDemoClient()) {
        appInsightsLogger.info(
          `SilentRenew Exception -  Refresh Token: ${userInStorage?.refresh_token} Access token: ${userInStorage?.access_token}  Expires At: ${
            userInStorage?.expires_at
          }  IsExpired: ${userInStorage?.expired} Expires in: ${userInStorage?.expires_in} IsMobile: ${!!window.flutter_inappwebview ? true : false}`
        );
      }
      removeUser();
    });

    events.addUserLoaded((_) => {
      appInsightsLogger.info("userInStorage loaded");
      const userInStorage = AccessTokenState.getUser();
      trackEvents("Oidc_user_loaded", {
        timeStamp: userInStorage?.profile.iat.toString() ?? "",
        expires: userInStorage?.expires_at?.toString() ?? "",
      });

      const clientCode = ClientCodeState.get();
      const wisdomUser = UserState.get();

      if (ClientCodeState.isDemoClient()) {
        appInsightsLogger.info(
          `Token Details -  Refresh Token: ${userInStorage?.refresh_token} Access token: ${userInStorage?.access_token}  Expires At: ${userInStorage?.expires_at} IsMobile: ${isMobileApp}`
        );
      }

      if (isMobileApp && userInStorage && clientCode && wisdomUser) {
        if (ClientCodeState.isDemoClient()) {
          appInsightsLogger.info(
            `Calling autheticateMobileUser - userInStorage loaded: refreshToken: ${userInStorage?.refresh_token} accessToken:${userInStorage?.access_token}, clientCode: ${clientCode}`
          );
        }
        authenticateMobileUser(clientCode, userInStorage?.toStorageString(), wisdomUser.id);
        trackEvents("Authenticate_Mobile_User", {
          timeStamp: userInStorage?.profile.iat.toString() ?? "",
          expires: userInStorage?.expires_at?.toString() ?? "",
        });
      }
    });

    events.addUserUnloaded(() => {
      appInsightsLogger.info("userInStorage unloaded");
      const userInStorage = AccessTokenState.getUser();
      trackEvents("Oidc_user_unloaded", {
        timeStamp: userInStorage?.profile.iat.toString() ?? "",
        expires: userInStorage?.expires_at?.toString() ?? "",
      });
    });
    addedAuthEvents = true;
  }

  return {
    signinRedirect,
    signoutRedirect,
    signinSilent,
    isAuthenticated,
    shouldAuthenticate,
    isLoading,
  };
};
